import '../../scss/components/EventCard.scss';
import NoImage from '../../img/NoImage.png'

const EventCard = ({name, game, date, time, where, signup, stream, details, imgUrl}) => 
  <section className="eventCard">
    {imgUrl
      ? <div alt={`${name} banner`} style={{backgroundImage: `url(${imgUrl})`}} />
      : <img alt={`${name} banner`} src={NoImage} />
    }
    <main>
      <h1>{name}</h1>
      <h3>{date} @ {time}</h3>
      <h4>{where}</h4>
      {game.toLowerCase() !== "multi" &&
        <h2>{game}</h2>
      }
      
      <p>{details}</p>

      <div className="links">
        <p>
        {
          stream.toLowerCase() === "none" || stream.toLowerCase() === "n/a" ?
            null
          : stream.startsWith("https://") ?
            <a href={stream}>Stream</a>
          :
            stream
        } {((stream === "none" && signup === "none") || (stream === "n/a" && signup === "n/a")) ? "" : "|"} {
          signup.toLowerCase() === "none" || signup.toLowerCase() === "n/a" ?
            null
          : signup.startsWith("https://") ?
            <a href={signup}>Sign-Up</a>
          :
            `Sign-up: ${signup}`
        }
        </p>
      </div>
    </main>
             
  </section>

export default EventCard;