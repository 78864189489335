const REGIONS = [
  {
    name: "Dublin",
    discordLink: "https://discord.gg/0k8YDBGVSKMuS1PM",
    imgSrc: "https://i.imgur.com/7DOBnfW.jpg",
    imgCreditName: "@DeanDush",
    imgCreditLink: "https://twitter.com/DeanDush",
    about: ["Although there were Fighting Games being played in the arcades of Dublin as far back as the 90s, the Dublin FGC as we know it today was established in 2009. With the release of Street Fighter 4, a small group of dedicated players managed to convince [boards.ie](https://boards.ie) to open the (now defunct) [Fighting Game forum](https://www.boards.ie/vbulletin/forumdisplay.php?f=1204) that would serve as the central hub for the entire IFGC for around 8 years.", "For 12 years now the Dublin community has met at countless locations to run Fighting Game events. They run events ranging from small weekly casuals to large multigame tournaments, and Ireland's International Premiere event Celtic Throwdown is run in Dublin every year."],
    weekly: ["You can find the Dublin FGC almost every week at [Capital Carnage](https://www.facebook.com/events/455469915208372), a casual and friendly event run in the basement of [The Black Sheep](https://goo.gl/maps/27rArWkW1reNg8fv7)"]
  },
  {
    name: "Cork",
    discordLink: "https://discord.gg/D7uK2xvmqW",
    twitterLink: "https://twitter.com/corkfgc",
    imgSrc: "https://i.imgur.com/uN9uQFG.jpg",
    imgCreditName: "@ChrisIWS",
    imgCreditLink: "https://twitter.com/ChrisIWS",
    about: ["Carrying the torch from the Tempest series of tournaments held during the early days of Street Fighter 4 by true Cork O.G., [Sairus](https://twitter.com/Cellsai), Cork FGC's beginnings were born of the desire to host more regular weekly casual meetings as well as holding monthly tournaments, mostly coordinating through a FaceBook group.", "The community has grown steadily over the years, with the dedication of its members resulting in the creation of the DBFZ Ireland discord, producing some of the countries hypest DK dittoes at the Rebellion and Rebellion Jr. Super Smash Bros Ultimate tournaments, as well as becoming a fixture in the Road to Celtic Throwdown series of regional tournaments."],
    weekly: ["Cork FGC meets for weekly casuals and a monthly tournament in a multitude of games at [365 IT Hub](https://www.google.ie/maps/place/365+IT+HUB/@51.900988,-8.4710426,17z/data=!3m1!4b1!4m5!3m4!1s0x48449196938413d5:0x7ca017d69a549720!8m2!3d51.9009847!4d-8.4688539?hl=en) on Cork city's St. Patrick's Quay. Details of upcoming events can be found through our [Discord](https://discord.gg/D7uK2xvmqW) or [Twitter](https://twitter.com/corkfgc) accounts"]
  }
]

export default REGIONS;