import '../../scss/pages/Socials.scss'

import FacebookLogo from '../../img/FacebookLogo.png'
import DiscordLogo from '../../img/DiscordLogo.png'
import TwitterLogo from '../../img/TwitterLogo.png'

const SOCIALS = [
  {name: "Facebook", link: "https://www.facebook.com/groups/irishfgc", img: FacebookLogo},
  {name: "Discord", link: "https://discord.gg/0k8YDBGVSKMuS1PM", img: DiscordLogo},
  {name: "Twitter", link: "https://twitter.com/IrishFGC", img: TwitterLogo},
]

const Socials = () =>
  <article className="socials">
    <section>
      {SOCIALS.map(socialEntry =>
        <a key={`${socialEntry.name}-logo`} href={socialEntry.link}>
          <img src={socialEntry.img} alt={`${socialEntry.name} Logo`} />
        </a>
      )}
    </section>
  </article>

export default Socials;