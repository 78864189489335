import './App.scss';
import {
	HashRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import Logo from './js/components/Logo';
import NavBar from './js/components/NavBar';


import Discords from './js/pages/Discords';
import Regions from './js/pages/Regions';
import Socials from './js/pages/Socials';
import Events from './js/pages/Events';

const App = () => {
	return (
		<div className="App">
      <Logo></Logo>
			<Router>
        <NavBar></NavBar>
				
				<Switch>
          <Route path={`/discords`}>
						<Discords />
					</Route>
					<Route path={`/regions`}>
						<Regions />
					</Route>
					<Route path={`/events`}>
						<Events />
					</Route>
					<Route path={`/socials`}>
						<Socials />
					</Route>
					<Redirect from='*' to='/discords' />
				</Switch>

			</Router>        
		</div>
	)
};

export default App;