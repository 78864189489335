import ReactMarkdown from 'react-markdown'
import '../../scss/pages/Regions.scss'
import DiscordLogo from '../../img/DiscordLogo.png'
import TwitterLogo from '../../img/TwitterLogo.png'
import REGIONS from '../constants/Regions'

const Regions = () => {
  return(
    <article className="regions">
      {REGIONS.map(regionEntry =>
        <section key={`region-entry=${regionEntry.name}`}>
          <header style={{backgroundImage: `url(${regionEntry.imgSrc})`}}>
            <h1>{regionEntry.name}</h1>
            <p>Image by: <a href={regionEntry.imgCreditLink}>{regionEntry.imgCreditName}</a></p>
          </header>
          <main>
            <div>
              <h3>About Us</h3>
              {regionEntry.about.map((aboutParagraph, index) =>
                <ReactMarkdown key={`about-para-${index}`} children={aboutParagraph} />  
              )}
            </div>

            <div>
              <h3>Weekly Event</h3>
              {regionEntry.weekly.map((weeklyParagraph, index) =>
                <ReactMarkdown key={`weekly-para-${index}`} children={weeklyParagraph} />  
              )}
            </div>
            
          </main>
          <footer>
            {regionEntry.discordLink &&
              <img alt="discord logo" src={DiscordLogo} />
            }
            {regionEntry.twitterLink &&
              <img alt="twitter logo" src={TwitterLogo} />
            }
            
          </footer>
          
      </section>
      )}
      
    </article>
  )
}
 

export default Regions;