import '../../scss/components/DiscordCard.scss'

const DiscordCard = ({name, desc, link, linkText}) => 
  <div className="discordCard">
    <div className="cardTop">
      <img alt={`${name}-discord-logo`} src={`${process.env.PUBLIC_URL}/discordimages/${name.toLowerCase().replaceAll(" ", "")}.png`} />
      
      <div className="text">
        <h1>{name}</h1>  
        <a href={link}>{!linkText ? "Click here to join" : linkText}</a>
      </div>
    </div>
    
    <p>{desc}</p>
  </div>

export default DiscordCard;